import React, { useEffect, useRef } from 'react';

import { CheckCircleOutlined, PlusOutlined } from '@ant-design/icons';
import {
  Col,
  Input,
  Modal,
  Row,
  Space,
  Spin,
  Switch,
  Tabs,
  Typography,
  Upload
} from 'antd';
import { firebase } from '../../../../firebase';
import AntCard from './DesignCard';
import './designCenter.css';
import FieldConfig from './FieldConfig';
import ShowCardPreview from './ShowCardPreview';
import Loader from '../../../Loader/Loader';
import api from '../../../../common/api';
function DesignCenter({
  orderUniqueId,
  uploadInProgress,
  setCardData,
  frontImageUploading,
  backgroundImageUploading,
  handleBackgroundImageUpload,
  frontCardImage,
  backCardImage,
  onFileInput,
  sameBackgroundColor,
  setSameBackgroundColor,
  backgroundColorFront,
  setFrontCardImage,
  setBackCardImage,
  setBackgroundColorBack,
  setBackgroundColorFront,
  backgroundColorBack,
  currentTab,
  setCurrentTab,
  cardData,
  setHideField,
  newField,
  setNewField,
  front,
  setFront,
  back,
  setBack,
  fontData,
  setFrontImageUploading,
  setBackgroundImageUploading,
  frontNode,
  backNode,
  isSaveQrClicked,
  setIsTabSwitched,
  activeUserTab,
  fetchingPreviousDesign = false,
  isEditModeOn = false,
  wait
}) {
  const frontRef = useRef(null);
  const backRef = useRef(null);

  async function getSet() {
    const response = await api?.get(
      `${process.env.REACT_APP_API_GTW_URL}/v1/best-factory/orders/${orderUniqueId}`
    );
    let items = [];
    response.data.data.set.cardUsers.forEach((user) =>
      items.push({
        ...user,
        fullName: `${user?.firstName} ${user?.lastName}`,
        workPhoneNumber: `${user?.workPhoneCountryCode ?? ''} ${
          user?.workPhone ?? ''
        }`,
        mobilePhoneNumber: `${user?.mobileCountryCode ?? ''} ${
          user?.mobilePhone ?? ''
        }`,
        cityStateZipCode: `${user?.city ?? ''}${
          user?.city && user?.state ? ', ' : ''
        }${user?.state ?? ''}${
          (user?.city || user?.state) && user?.zipcode ? ', ' : ''
        }${user?.zipcode ?? ''}`
      })
    );
    setCardData(items);
  }
  useEffect(() => {
    getSet();
  }, []);

  return (
    <div>
      <Row justify="space-between" align="middle">
        <Col span={6}>
          <Space>
            <>
              {currentTab === 'front' ? (
                <Upload
                  style={{ width: '50%' }}
                  name="Front"
                  listType="picture-card"
                  showUploadList={false}
                  action={(file) => {
                    handleBackgroundImageUpload(file, 'front');
                  }}
                >
                  {frontCardImage ? (
                    <img
                      src={frontCardImage}
                      alt="avatar"
                      style={{ width: '100%' }}
                    />
                  ) : (
                    <div>
                      {frontImageUploading ? (
                        <Spin />
                      ) : (
                        <>
                          <PlusOutlined />
                          <div style={{ marginTop: 8 }}>Front PNG</div>
                        </>
                      )}
                    </div>
                  )}
                </Upload>
              ) : (
                <Upload
                  name="Back"
                  listType="picture-card"
                  showUploadList={false}
                  action={(file) => {
                    handleBackgroundImageUpload(file, 'back');
                  }}
                  disabled={backgroundImageUploading}
                >
                  {backCardImage ? (
                    <img
                      src={backCardImage}
                      alt="avatar"
                      style={{ width: '100%' }}
                    />
                  ) : (
                    <div>
                      {backgroundImageUploading ? (
                        <Spin />
                      ) : (
                        <>
                          <PlusOutlined />
                          <div style={{ marginTop: 8 }}>Back PNG</div>
                        </>
                      )}
                    </div>
                  )}
                </Upload>
              )}
            </>
          </Space>
        </Col>
        <Col span={4}>
          {uploadInProgress ? (
            <Spin />
          ) : (
            <>
              <label className="custom-file-upload" htmlFor="file-upload">
                {fontData ? (
                  <CheckCircleOutlined style={{ color: 'green' }} />
                ) : (
                  'Font'
                )}
              </label>
              <input
                id="file-upload"
                type="file"
                onInput={onFileInput}
                accept=".ttf,.otf"
                multiple
                style={{ display: 'none' }}
              />
            </>
          )}
        </Col>
        <Col span={4}>
          <Typography.Text>
            Same background color with template:
          </Typography.Text>
          <Switch
            defaultChecked={sameBackgroundColor}
            onChange={(e) => {
              setSameBackgroundColor(e);
              if (e) {
                setBackgroundColorBack(backgroundColorFront);
                setBackgroundColorFront(backgroundColorFront);
              }
            }}
          />
        </Col>
        <Col span={4}>
          {sameBackgroundColor ? (
            <>
              <div className="flex column align-center">
                <label>Color</label>
                <Input
                  type="color"
                  value={backgroundColorFront}
                  className="w-100"
                  onChange={async (e) => {
                    if (frontCardImage || backCardImage) {
                      Modal.confirm({
                        title: 'Warning!',
                        content:
                          'If you select a Background Color on this side of the card, the PNG image will be overwritten. Continue?',
                        okText: 'OK',
                        cancelText: 'Cancel',
                        onOk() {
                          setFrontCardImage();
                          setBackCardImage();
                          setFrontImageUploading(false);
                          setBackgroundImageUploading(false);
                        },
                        okCancel() {}
                      });
                    } else {
                      setBackgroundColorBack(e?.target?.value);
                      setBackgroundColorFront(e?.target?.value);
                      // The tab switching is awaited for a second because it takes sometime to generate the HTML of
                      // current tab(Tab switching is automated for generating HTML so user need not to switch manually)
                      await wait(1000);
                      setCurrentTab(currentTab === 'front' ? 'back' : 'front');
                    }
                  }}
                />
              </div>
            </>
          ) : (
            <div className="flex column">
              {currentTab === 'front' ? (
                <div className="flex row align-center">
                  <label className="mr-4">Front color</label>
                  <Input
                    type="color"
                    value={backgroundColorFront}
                    className="w-100"
                    onChange={(e) => {
                      setBackgroundColorFront(e?.target?.value);
                      if (currentTab === 'back') {
                        setCurrentTab('front');
                      }
                    }}
                  />
                </div>
              ) : (
                <div className="flex row align-center">
                  <label className="mr-4">Back color</label>
                  <Input
                    type="color"
                    value={backgroundColorBack}
                    className="w-100"
                    onChange={(e) => {
                      setBackgroundColorBack(e?.target?.value);
                      if (currentTab === 'front') {
                        setCurrentTab('back');
                      }
                    }}
                  />
                </div>
              )}
            </div>
          )}
        </Col>
      </Row>
      {!fetchingPreviousDesign || !isEditModeOn ? (
        <div>
          <div className="preview-wrapper">
            <h4 className="preview-text">See the preview of designed card</h4>
            <ShowCardPreview frontNode={frontNode} backNode={backNode} />
          </div>
          <Tabs
            onTabClick={(key, e) => {
              if (isSaveQrClicked) {
                setIsTabSwitched(true);
              }
              setCurrentTab(key);
            }}
            activeKey={currentTab}
            tabPosition="left"
          >
            <Tabs.TabPane tab="Front" key="front">
              <div className="design-container">
                <Row justify="space-between">
                  <Col span={24}>
                    <div id="front">
                      <div className="card-container">
                        <div ref={frontRef}>
                          <div
                            id="card-layout-front-wrapper"
                            style={{
                              width: '407px',
                              border: '1px solid rgba(0, 0, 0, 0.06)'
                            }}
                            className="card-layout-front-wrapper"
                          >
                            <AntCard
                              cardId="front-inner"
                              backgroundColor={backgroundColorFront}
                              cardData={cardData}
                              currentTab={currentTab}
                              newField={newField}
                              front={front}
                              setFront={setFront}
                              back={back}
                              setBack={setBack}
                              backgroundImage={frontCardImage}
                              side="front"
                              activeUserTab={activeUserTab}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col span={24}>
                    <div className="configurations">
                      <Row style={{ width: '100%' }} justify="space-between">
                        <Col span={3}>
                          <Typography.Text strong>Text Fields</Typography.Text>
                        </Col>
                        <Col span={2}>
                          <Typography.Text strong>Font Type</Typography.Text>
                        </Col>
                        <Col span={2}>
                          <Typography.Text strong>Font Color</Typography.Text>
                        </Col>
                        <Col span={2}>
                          <Typography.Text strong>Font Size</Typography.Text>
                        </Col>
                        <Col span={2}>
                          <Typography.Text strong>Alignment</Typography.Text>
                        </Col>
                        <Col span={3}>
                          <Typography.Text strong>
                            Coordinates X & Y
                          </Typography.Text>
                        </Col>
                        <Col span={6}>
                          <Typography.Text strong>
                            Padding T, R, B, L
                          </Typography.Text>
                        </Col>
                        {/* Rotation is the part of next sprint so that commenting UI for now */}
                        {/* <Col span={2}>
                          <Typography.Text strong>Rotation</Typography.Text>
                        </Col> */}
                        <Col span={1}>
                          <Typography.Text strong>Dynamic</Typography.Text>
                        </Col>
                      </Row>
                      <FieldConfig
                        textField="Name"
                        fonts={fontData}
                        currentTab={currentTab}
                        fieldId="fullName"
                        cardData={cardData}
                        setCardData={setCardData}
                        front={front}
                        setFront={setFront}
                        back={back}
                        setBack={setBack}
                        activeUserTab={activeUserTab}
                      />
                      <FieldConfig
                        textField="First Name"
                        fonts={fontData}
                        currentTab={currentTab}
                        fieldId="firstName"
                        cardData={cardData}
                        setCardData={setCardData}
                        front={front}
                        setFront={setFront}
                        back={back}
                        setBack={setBack}
                        activeUserTab={activeUserTab}
                      />
                      <FieldConfig
                        textField="Last Name"
                        fonts={fontData}
                        currentTab={currentTab}
                        fieldId="lastName"
                        cardData={cardData}
                        setCardData={setCardData}
                        front={front}
                        setFront={setFront}
                        back={back}
                        setBack={setBack}
                        activeUserTab={activeUserTab}
                      />
                      <FieldConfig
                        textField="Email"
                        fonts={fontData}
                        currentTab={currentTab}
                        fieldId="email"
                        cardData={cardData}
                        setCardData={setCardData}
                        front={front}
                        setFront={setFront}
                        back={back}
                        setBack={setBack}
                        activeUserTab={activeUserTab}
                      />
                      <FieldConfig
                        textField="LinkedIn"
                        fonts={fontData}
                        currentTab={currentTab}
                        fieldId="linkedIn"
                        cardData={cardData}
                        setCardData={setCardData}
                        front={front}
                        setFront={setFront}
                        back={back}
                        setBack={setBack}
                        activeUserTab={activeUserTab}
                      />
                      <FieldConfig
                        textField="Phone(Mobile)"
                        fonts={fontData}
                        currentTab={currentTab}
                        fieldId="mobilePhone"
                        cardData={cardData}
                        setCardData={setCardData}
                        front={front}
                        setFront={setFront}
                        back={back}
                        setBack={setBack}
                        activeUserTab={activeUserTab}
                      />
                      <FieldConfig
                        textField="Phone(country code + Mobile)"
                        fonts={fontData}
                        currentTab={currentTab}
                        fieldId="mobilePhoneNumber"
                        cardData={cardData}
                        setCardData={setCardData}
                        front={front}
                        setFront={setFront}
                        back={back}
                        setBack={setBack}
                        activeUserTab={activeUserTab}
                      />
                      <FieldConfig
                        textField="Phone(Work)"
                        fonts={fontData}
                        currentTab={currentTab}
                        fieldId="workPhone"
                        cardData={cardData}
                        setCardData={setCardData}
                        front={front}
                        setFront={setFront}
                        back={back}
                        setBack={setBack}
                        activeUserTab={activeUserTab}
                      />
                      <FieldConfig
                        textField="Phone(country code + Work)"
                        fonts={fontData}
                        currentTab={currentTab}
                        fieldId="workPhoneNumber"
                        cardData={cardData}
                        setCardData={setCardData}
                        front={front}
                        setFront={setFront}
                        back={back}
                        setBack={setBack}
                        activeUserTab={activeUserTab}
                      />
                      <FieldConfig
                        textField="Company"
                        fonts={fontData}
                        currentTab={currentTab}
                        fieldId="company"
                        cardData={cardData}
                        setCardData={setCardData}
                        front={front}
                        setFront={setFront}
                        back={back}
                        setBack={setBack}
                        activeUserTab={activeUserTab}
                      />
                      <FieldConfig
                        textField="Title"
                        fonts={fontData}
                        currentTab={currentTab}
                        fieldId="title"
                        cardData={cardData}
                        setCardData={setCardData}
                        front={front}
                        setFront={setFront}
                        back={back}
                        setBack={setBack}
                        activeUserTab={activeUserTab}
                      />
                      <FieldConfig
                        textField="Address1"
                        fonts={fontData}
                        currentTab={currentTab}
                        fieldId="addressLine1"
                        cardData={cardData}
                        setCardData={setCardData}
                        front={front}
                        setFront={setFront}
                        back={back}
                        setBack={setBack}
                        activeUserTab={activeUserTab}
                      />
                      <FieldConfig
                        textField="Address2"
                        fonts={fontData}
                        currentTab={currentTab}
                        fieldId="addressLine2"
                        cardData={cardData}
                        setCardData={setCardData}
                        front={front}
                        setFront={setFront}
                        back={back}
                        setBack={setBack}
                        activeUserTab={activeUserTab}
                      />
                      <FieldConfig
                        textField="City"
                        fonts={fontData}
                        currentTab={currentTab}
                        fieldId="city"
                        cardData={cardData}
                        setCardData={setCardData}
                        front={front}
                        setFront={setFront}
                        back={back}
                        setBack={setBack}
                        activeUserTab={activeUserTab}
                      />
                      <FieldConfig
                        textField="State"
                        fonts={fontData}
                        currentTab={currentTab}
                        fieldId="state"
                        cardData={cardData}
                        setCardData={setCardData}
                        front={front}
                        setFront={setFront}
                        back={back}
                        setBack={setBack}
                        activeUserTab={activeUserTab}
                      />
                      <FieldConfig
                        textField="Zipcode"
                        fonts={fontData}
                        currentTab={currentTab}
                        fieldId="zipcode"
                        cardData={cardData}
                        setCardData={setCardData}
                        front={front}
                        setFront={setFront}
                        back={back}
                        setBack={setBack}
                        activeUserTab={activeUserTab}
                      />
                      <FieldConfig
                        textField="Country"
                        fonts={fontData}
                        currentTab={currentTab}
                        fieldId="country"
                        cardData={cardData}
                        setCardData={setCardData}
                        front={front}
                        setFront={setFront}
                        back={back}
                        setBack={setBack}
                        activeUserTab={activeUserTab}
                      />
                      <FieldConfig
                        textField="City, State, Zipcode"
                        fonts={fontData}
                        currentTab={currentTab}
                        fieldId="cityStateZipCode"
                        cardData={cardData}
                        setCardData={setCardData}
                        front={front}
                        setFront={setFront}
                        back={back}
                        setBack={setBack}
                        activeUserTab={activeUserTab}
                      />
                      <FieldConfig
                        textField="+Add custom field"
                        fonts={fontData}
                        currentTab={currentTab}
                        fieldId="customField"
                        cardData={cardData}
                        setCardData={setCardData}
                        front={front}
                        setFront={setFront}
                        back={back}
                        setBack={setBack}
                        activeUserTab={activeUserTab}
                        setHideField={setHideField}
                        newField={newField}
                        setNewField={setNewField}
                      />
                    </div>
                  </Col>
                </Row>
              </div>
            </Tabs.TabPane>
            <Tabs.TabPane tab="Back" key="back">
              <div className="design-container">
                <Row justify="space-between">
                  <Col span={24}>
                    <div id="back">
                      <div className="card-container">
                        <div ref={backRef}>
                          <div
                            id="card-layout-back-wrapper"
                            style={{
                              width: '407px',
                              border: '1px solid rgba(0, 0, 0, 0.06)'
                            }}
                            className="card-layout-back-wrapper"
                          >
                            <AntCard
                              cardId="back-inner"
                              backgroundColor={backgroundColorBack}
                              cardData={cardData}
                              currentTab={currentTab}
                              newField={newField}
                              front={front}
                              setFront={setFront}
                              back={back}
                              setBack={setBack}
                              backgroundImage={backCardImage}
                              side="back"
                              activeUserTab={activeUserTab}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col span={24}>
                    <div className="configurations">
                      <Row style={{ width: '100%' }} justify="space-between">
                        <Col span={3}>
                          <Typography.Text strong>Text Fields</Typography.Text>
                        </Col>
                        <Col span={2}>
                          <Typography.Text strong>Font Type</Typography.Text>
                        </Col>
                        <Col span={2}>
                          <Typography.Text strong>Font Color</Typography.Text>
                        </Col>
                        <Col span={2}>
                          <Typography.Text strong>Font Size</Typography.Text>
                        </Col>
                        <Col span={2}>
                          <Typography.Text strong>Alignment</Typography.Text>
                        </Col>
                        <Col span={3}>
                          <Typography.Text strong>
                            Coordinates X & Y
                          </Typography.Text>
                        </Col>
                        <Col span={6}>
                          <Typography.Text strong>
                            Padding T, R, B, L
                          </Typography.Text>
                        </Col>
                        {/* Rotation is the part of next sprint so that commenting UI for now */}
                        {/* <Col span={2}>
                          <Typography.Text strong>Rotation</Typography.Text>
                        </Col> */}
                        <Col span={1}>
                          <Typography.Text strong>Dynamic</Typography.Text>
                        </Col>
                      </Row>
                      <FieldConfig
                        textField="Name"
                        fonts={fontData}
                        currentTab={currentTab}
                        fieldId="fullName"
                        cardData={cardData}
                        setCardData={setCardData}
                        front={front}
                        setFront={setFront}
                        back={back}
                        setBack={setBack}
                        activeUserTab={activeUserTab}
                      />
                      <FieldConfig
                        textField="First Name"
                        fonts={fontData}
                        currentTab={currentTab}
                        fieldId="firstName"
                        cardData={cardData}
                        setCardData={setCardData}
                        front={front}
                        setFront={setFront}
                        back={back}
                        setBack={setBack}
                        activeUserTab={activeUserTab}
                      />
                      <FieldConfig
                        textField="Last Name"
                        fonts={fontData}
                        currentTab={currentTab}
                        fieldId="lastName"
                        cardData={cardData}
                        setCardData={setCardData}
                        front={front}
                        setFront={setFront}
                        back={back}
                        setBack={setBack}
                        activeUserTab={activeUserTab}
                      />
                      <FieldConfig
                        textField="Email"
                        fonts={fontData}
                        currentTab={currentTab}
                        fieldId="email"
                        cardData={cardData}
                        setCardData={setCardData}
                        front={front}
                        setFront={setFront}
                        back={back}
                        setBack={setBack}
                        activeUserTab={activeUserTab}
                      />
                      <FieldConfig
                        textField="LinkedIn"
                        fonts={fontData}
                        currentTab={currentTab}
                        fieldId="linkedIn"
                        cardData={cardData}
                        setCardData={setCardData}
                        front={front}
                        setFront={setFront}
                        back={back}
                        setBack={setBack}
                        activeUserTab={activeUserTab}
                      />
                      <FieldConfig
                        textField="Phone(Mobile)"
                        fonts={fontData}
                        currentTab={currentTab}
                        fieldId="mobilePhone"
                        cardData={cardData}
                        setCardData={setCardData}
                        front={front}
                        setFront={setFront}
                        back={back}
                        setBack={setBack}
                        activeUserTab={activeUserTab}
                      />
                      <FieldConfig
                        textField="Phone(country code + Mobile)"
                        fonts={fontData}
                        currentTab={currentTab}
                        fieldId="mobilePhoneNumber"
                        cardData={cardData}
                        setCardData={setCardData}
                        front={front}
                        setFront={setFront}
                        back={back}
                        setBack={setBack}
                        activeUserTab={activeUserTab}
                      />
                      <FieldConfig
                        textField="Phone(Work)"
                        fonts={fontData}
                        currentTab={currentTab}
                        fieldId="workPhone"
                        cardData={cardData}
                        setCardData={setCardData}
                        front={front}
                        setFront={setFront}
                        back={back}
                        setBack={setBack}
                        activeUserTab={activeUserTab}
                      />
                      <FieldConfig
                        textField="Phone(country code + Work)"
                        fonts={fontData}
                        currentTab={currentTab}
                        fieldId="workPhoneNumber"
                        cardData={cardData}
                        setCardData={setCardData}
                        front={front}
                        setFront={setFront}
                        back={back}
                        setBack={setBack}
                        activeUserTab={activeUserTab}
                      />
                      <FieldConfig
                        textField="Company"
                        fonts={fontData}
                        currentTab={currentTab}
                        fieldId="company"
                        cardData={cardData}
                        setCardData={setCardData}
                        front={front}
                        setFront={setFront}
                        back={back}
                        setBack={setBack}
                        activeUserTab={activeUserTab}
                      />
                      <FieldConfig
                        textField="Title"
                        fonts={fontData}
                        currentTab={currentTab}
                        fieldId="title"
                        cardData={cardData}
                        setCardData={setCardData}
                        front={front}
                        setFront={setFront}
                        back={back}
                        setBack={setBack}
                        activeUserTab={activeUserTab}
                      />
                      <FieldConfig
                        textField="Address1"
                        fonts={fontData}
                        currentTab={currentTab}
                        fieldId="addressLine1"
                        cardData={cardData}
                        setCardData={setCardData}
                        front={front}
                        setFront={setFront}
                        back={back}
                        setBack={setBack}
                        activeUserTab={activeUserTab}
                      />
                      <FieldConfig
                        textField="Address2"
                        fonts={fontData}
                        currentTab={currentTab}
                        fieldId="addressLine2"
                        cardData={cardData}
                        setCardData={setCardData}
                        front={front}
                        setFront={setFront}
                        back={back}
                        setBack={setBack}
                        activeUserTab={activeUserTab}
                      />
                      <FieldConfig
                        textField="City"
                        fonts={fontData}
                        currentTab={currentTab}
                        fieldId="city"
                        cardData={cardData}
                        setCardData={setCardData}
                        front={front}
                        setFront={setFront}
                        back={back}
                        setBack={setBack}
                        activeUserTab={activeUserTab}
                      />
                      <FieldConfig
                        textField="State"
                        fonts={fontData}
                        currentTab={currentTab}
                        fieldId="state"
                        cardData={cardData}
                        setCardData={setCardData}
                        front={front}
                        setFront={setFront}
                        back={back}
                        setBack={setBack}
                        activeUserTab={activeUserTab}
                      />
                      <FieldConfig
                        textField="Zipcode"
                        fonts={fontData}
                        currentTab={currentTab}
                        fieldId="zipcode"
                        cardData={cardData}
                        setCardData={setCardData}
                        front={front}
                        setFront={setFront}
                        back={back}
                        setBack={setBack}
                        activeUserTab={activeUserTab}
                      />
                      <FieldConfig
                        textField="Country"
                        fonts={fontData}
                        currentTab={currentTab}
                        fieldId="country"
                        cardData={cardData}
                        setCardData={setCardData}
                        front={front}
                        setFront={setFront}
                        back={back}
                        setBack={setBack}
                        activeUserTab={activeUserTab}
                      />
                      <FieldConfig
                        textField="City, State, Zipcode"
                        fonts={fontData}
                        currentTab={currentTab}
                        fieldId="cityStateZipCode"
                        cardData={cardData}
                        setCardData={setCardData}
                        front={front}
                        setFront={setFront}
                        back={back}
                        setBack={setBack}
                        activeUserTab={activeUserTab}
                      />
                      <FieldConfig
                        textField="+Add custom field"
                        fonts={fontData}
                        currentTab={currentTab}
                        fieldId="customField"
                        cardData={cardData}
                        setCardData={setCardData}
                        front={front}
                        setFront={setFront}
                        back={back}
                        setBack={setBack}
                        activeUserTab={activeUserTab}
                        setHideField={setHideField}
                        newField={newField}
                        setNewField={setNewField}
                      />
                    </div>
                  </Col>
                </Row>
              </div>
            </Tabs.TabPane>
          </Tabs>
        </div>
      ) : (
        <Loader />
      )}
    </div>
  );
}

export default DesignCenter;
